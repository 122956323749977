import { memoize } from './common';
import { bind } from './snabbdom';
const longPressDuration = 610;
export function bindMobileTapHold(el, f, redraw) {
    let longPressCountdown;
    el.addEventListener('touchstart', e => {
        longPressCountdown = setTimeout(() => {
            f(e);
            if (redraw)
                redraw();
        }, longPressDuration);
    });
    el.addEventListener('touchmove', () => clearTimeout(longPressCountdown));
    el.addEventListener('touchcancel', () => clearTimeout(longPressCountdown));
    el.addEventListener('touchend', () => clearTimeout(longPressCountdown));
}
export const bindMobileMousedown = (f, redraw) => (el) => {
    for (const mousedownEvent of ['touchstart', 'mousedown']) {
        el.addEventListener(mousedownEvent, e => {
            f(e);
            e.preventDefault();
            if (redraw)
                redraw();
        }, { passive: false });
    }
};
export const hookMobileMousedown = (f) => bind('ontouchstart' in window ? 'click' : 'mousedown', f);
let col1cache = 'init';
export function isCol1() {
    if (typeof col1cache == 'string') {
        if (col1cache == 'init') {
            // only once
            window.addEventListener('resize', () => {
                col1cache = 'rec';
            }); // recompute on resize
            if (navigator.userAgent.indexOf('Edge/') > -1)
                // edge gets false positive on page load, fix later
                requestAnimationFrame(() => {
                    col1cache = 'rec';
                });
        }
        col1cache = !!window.getComputedStyle(document.body).getPropertyValue('---col1');
    }
    return col1cache;
}
export const isMobile = () => isAndroid() || isIOS();
export const isAndroid = () => /Android/.test(navigator.userAgent);
export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
export const isIOS = (constraint) => {
    let answer = ios();
    if (!constraint || !answer)
        return answer;
    const version = parseFloat(navigator.userAgent.slice(navigator.userAgent.indexOf('Version/') + 8));
    if (constraint?.below)
        answer = version < constraint.below;
    if (answer && constraint?.atLeast)
        answer = version >= constraint.atLeast;
    return answer;
};
export const isChrome = () => /Chrome\//.test(navigator.userAgent);
export const isFirefox = () => /Firefox/.test(navigator.userAgent);
export const getFirefoxMajorVersion = () => {
    const match = /Firefox\/(\d*)/.exec(navigator.userAgent);
    return match && match.length > 1 ? parseInt(match[1]) : undefined;
};
export const isIOSChrome = () => /CriOS/.test(navigator.userAgent);
export const isTouchDevice = () => !hasMouse();
export const isIPad = () => navigator?.maxTouchPoints > 2 && /iPad|Macintosh/.test(navigator.userAgent);
export const hasFeature = (feat) => !feat || features().includes(feat);
export const features = memoize(() => {
    const features = [];
    if (typeof WebAssembly === 'object' &&
        typeof WebAssembly.validate === 'function' &&
        WebAssembly.validate(Uint8Array.from([0, 97, 115, 109, 1, 0, 0, 0]))) {
        features.push('wasm');
        if (sharedMemoryTest()) {
            features.push('sharedMem');
            // i32x4.dot_i16x8_s, i32x4.trunc_sat_f64x2_u_zero
            const sourceWithSimd = Uint8Array.from([0, 97, 115, 109, 1, 0, 0, 0, 1, 12, 2, 96, 2, 123, 123, 1, 123, 96, 1, 123, 1, 123, 3, 3, 2, 0, 1, 7, 9, 2, 1, 97, 0, 0, 1, 98, 0, 1, 10, 19, 2, 9, 0, 32, 0, 32, 1, 253, 186, 1, 11, 7, 0, 32, 0, 253, 253, 1, 11]);  
            if (WebAssembly.validate(sourceWithSimd))
                features.push('simd');
        }
    }
    return Object.freeze(features);
});
const ios = memoize(() => /iPhone|iPod/.test(navigator.userAgent) || isIPad());
const hasMouse = memoize(() => window.matchMedia('(hover: hover) and (pointer: fine)').matches);
export const reducedMotion = memoize(() => window.matchMedia('(prefers-reduced-motion: reduce)').matches);
function sharedMemoryTest() {
    if (typeof Atomics !== 'object')
        return false;
    if (typeof SharedArrayBuffer !== 'function')
        return false;
    let mem;
    try {
        mem = new WebAssembly.Memory({ shared: true, initial: 1, maximum: 2 });
        if (!(mem.buffer instanceof SharedArrayBuffer))
            return false;
        window.postMessage(mem.buffer, '*');
    }
    catch (_) {
        return false;
    }
    return mem.buffer instanceof SharedArrayBuffer;
}
